@import url(https://fonts.googleapis.com/css?family=B612+Mono&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.clock {
}

.clock .date {
  font-size: 1em;
}

.clock .time {
  font-size: 3em;
}

.forecast {
  width: 65%;
  margin: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
}

.forecast .top {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.forecast .top .current {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 0.5 1;
}

.forecast .top .hourly {
  flex: 0.5 1;
  margin-left: 6px;
  max-width: 30%;
}

.forecast .top .current .temperature {
  margin-left: 70px;
}

.forecast .top .hourly .hour {
  display: flex;
  flex: 1 1;
  flex-direction: row;
}

.forecast .top .hourly .icon {
  flex: 1 1;
  padding-left: 7px;
}

.forecast .top .hourly .time {
  flex: 1 1;
  text-align: left;
}

.forecast .top .hourly .temperature {
  flex: 2 1;
  text-align: left;
  padding-left: 0px;
  margin-left: 10px;
  position: relative;
}

.forecast .top .hourly .temperature .continuum-line {
  border: 2px solid rgba(255, 255, 255, 0.02);
  background: rgba(255, 255, 255, 0.09);
  height: 10px;
  border-radius: 5px;
  width: 100%;
  position: absolute;
  top: 15px;
}

.forecast .top .hourly .temperature .number-container {
  background: rgba(10, 10, 10, 0.96);
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  height: 18px;
  width: 50%;
  font-size: 50%;
  overflow: hidden;
  position: relative;
  top: 12px;
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.5) 2px 2px 5px;
  opacity: 0.999;
}

.forecast .top .hourly .temperature .number-container .time-container {
  flex: 2 1;
  background: rgba(255, 255, 255, 0.75);
  height: 100%;
  display: flex;
  align-items: center;
}

.forecast .top .hourly .temperature .number-container .time {
  color: #222;
  font-weight: 600;
  text-align: center;
  padding-right: 2px;
}
.forecast .top .hourly .temperature .number-container .time .am-pm {
  display: none;
}
@media screen and (min-width: 1140px) {
  .forecast .top .hourly .temperature .number-container .time .am-pm {
    display: inline;
  }
}

.forecast .top .hourly .temperature .number-container .temp-container {
  flex: 1 1;
  text-align: center;
  font-weight: 600;
}

.forecast .top .hourly .temperature .number-container .temp-container .temp {
}

.forecast .future-summary {
  color: rgba(255, 255, 255, 0.7);
  margin-top: 5px;
  font-size: 0.7em;
}

.forecast .summary {
  width: 40%;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}

.forecast .summary .summary-text {
  border-bottom: 1px solid #aaa;
  margin-bottom: 5px;
}

.forecast .temperature {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.forecast .temperature .temperature-item {
  margin-top: 10px;
}

.forecast .temperature .temperature-item .data {
  font-weight: 600;
  font-size: 1.5em;
}

.forecast .temperature .temperature-item small {
  font-size: 0.7em;
}

.forecast .future-forecast {
  flex: 1 1;
  flex-direction: row;
}

.forecast .future-forecast {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.forecast .future-forecast .future-day {
  flex: 1 1;
  border-bottom: 1px solid #aaa;
  margin: 10px;
}

.forecast
  .future-forecast
  .future-day
  .future-day-forecast-temperature
  .future-day-forecast-temperature-high {
  border-bottom: 1px solid #555;
}

.note {
  font-size: 0.6em;
  color: rgba(255, 255, 255, 0.2);
}

.light {
  color: rgba(255, 255, 255, 0.5);
}

.item {
  margin-top: 5px;
}

.subtle-button {
  border: 0;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.2);
}

.clickable {
  cursor: pointer;
}

.settings-container {
  position: absolute;
  background-color: rgba(50, 50, 50, 0.95);
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.settings-container .settings {
}

.form-group {
  padding: 10px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.form-group label {
  font-size: 1.5em;
  color: #888;
}

.form-group input[type="text"] {
  border: 2px solid #555;
  padding: 10px;
  border-radius: 4px;
  color: #aaa;
  background-color: rgba(0, 0, 0, 0.2);
}

.subtle-text {
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.8em;
}

.row {
  display: flex;
  flex-direction: row;
  flex: 1 1;
}

.centered {
  justify-content: center;
  justify-items: center;
  align-content: center;
}

.row .col-center {
  flex: 1 1;
  text-align: center;
  justify-content: center;
}

